<template>
  <div
    id="project"
    :style="$store.style"
    v-if="$store.category && $store.project"
  >
    <vue-headful
      :title="$store.category.name + ' | Edgeryders'"
      :description="getDescription($store.category.description)"
    />
    <Header />

    <section id="subs" v-if="$store.subCategories.length > 0">
      <div class="container">
        <div class="sidebar">
          <div class="flex w-full mb-7 pb-0 justify-between">
            <h3>Activities</h3>
            <div class="inline-flex">
              <div
                class="nav_button back"
                :style="style"
                @click="changeSlide('prev', 'subs')"
              >
                <svg
                  class="chevron"
                  xmlns="http://www.w3.org/2000/svg"
                  stroke="white"
                  stroke-width="8"
                  viewBox="0 0 54 90"
                >
                  <path
                    d="M2.6 51.4l36 36a9 9 0 0012.8 0 9 9 0 000-12.8L21.7 45l29.7-29.6A9 9 0 1038.6 2.6l-36 36a9 9 0 000 12.8z"
                    :fill="'#' + $store.category.color"
                    fill-rule="nonzero"
                  />
                </svg>
              </div>

              <div
                class="nav_button next"
                :style="style"
                @click="changeSlide('next', 'subs')"
              >
                <svg
                  class="chevron"
                  xmlns="http://www.w3.org/2000/svg"
                  stroke="white"
                  stroke-width="8"
                  viewBox="0 0 54 90"
                >
                  <path
                    d="M51.4 51.4l-36 36a9 9 0 01-12.8 0 9 9 0 010-12.8L32.3 45 2.6 15.4A9 9 0 1115.4 2.6l36 36a9 9 0 010 12.8z"
                    :fill="'#' + $store.category.color"
                    fill-rule="nonzero"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div class="content">
          <swiper class="subslider swiper" :options="subSlider" ref="subs">
            <swiper-slide
              class="sub_item"
              :class="{ bg_image: item.uploaded_background }"
              v-for="(item, index) in $store.subCategories"
              style="
                border: 1px solid rgba(0, 0, 0, 0.04);
                padding: 25px 30px;
                margin: 0 0px 40px;
              "
              :style="'border-top: 2px solid #' + item.color"
              :key="index"
            >
              <div
                class="item_bg_header"
                v-if="item.uploaded_background && item.uploaded_background.url"
                @click="getProject(item.slug)"
                :style="
                  'background: url(https://edgeryders.eu/' +
                    item.uploaded_background.url +
                    ') #' +
                    item.color
                "
              >
                <h3 class="item_category" :style="'color: #fff'">
                  {{ item.name }}
                </h3>
              </div>

              <div
                v-else
                class="item_header pt-6"
                @click="getProject(item.slug)"
              >
                <h3 class="item_category flex" :style="'color: ' + item.color">
                  <img
                    v-if="item.uploaded_logo"
                    :src="'https://edgeryders.eu/' + item.uploaded_logo.url"
                  />
                  <p>{{ item.name }}</p>
                </h3>
              </div>

              <p
                class="inline info w-full"
                v-if="item.description"
                v-html="getDescription(item.description)"
              ></p>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </section>

    <section id="news" v-if="$store.project.news.length > 0">
      <div class="container">
        <div class="sidebar">
          <div class="flex w-full mb-4 pb-0 justify-between">
            <h3>News</h3>
            <div class="inline-flex">
              <div
                class="nav_button back"
                :style="style"
                @click="changeSlide('prev', 'news')"
              >
                <svg
                  class="chevron"
                  xmlns="http://www.w3.org/2000/svg"
                  stroke="white"
                  stroke-width="8"
                  viewBox="0 0 54 90"
                >
                  <path
                    d="M2.6 51.4l36 36a9 9 0 0012.8 0 9 9 0 000-12.8L21.7 45l29.7-29.6A9 9 0 1038.6 2.6l-36 36a9 9 0 000 12.8z"
                    :fill="'#' + $store.category.color"
                    fill-rule="nonzero"
                  />
                </svg>
              </div>

              <div
                class="nav_button next"
                :style="style"
                @click="changeSlide('next', 'news')"
              >
                <svg
                  class="chevron"
                  xmlns="http://www.w3.org/2000/svg"
                  stroke="white"
                  stroke-width="8"
                  viewBox="0 0 54 90"
                >
                  <path
                    d="M51.4 51.4l-36 36a9 9 0 01-12.8 0 9 9 0 010-12.8L32.3 45 2.6 15.4A9 9 0 1115.4 2.6l36 36a9 9 0 010 12.8z"
                    :fill="'#' + $store.category.color"
                    fill-rule="nonzero"
                  />
                </svg>
              </div>
            </div>
          </div>

          <p class="updated hidden">
            Last updated
            {{ updates[0].created_at | dateShort }} at
            {{ updates[0].created_at | timeShort }}
          </p>
        </div>
        <div class="content">
          <swiper class="swiper" :options="newsSlider" ref="news">
            <swiper-slide
              class="news_item"
              v-for="(item, index) in $store.project.news"
              :key="index"
            >
              <div @click="goTo('news', item.id)">
                <p class="date">
                  {{ item.created_at | dateLong }}
                </p>
                <a
                  class="
                    title
                    inline-block
                    w-full
                    text-xl
                    leading-tight
                    tracking-tight
                    border-b
                    mb-4
                    pb-4
                    mt-1
                    font-bold
                  "
                  v-html="markdownRender(item.title)"
                ></a>
                <div class="excerpt" v-html="item.excerpt"></div>
                <div
                  class="open_button"
                  @click="goTo('news', item.id)"
                  :style="{ backgroundColor: '#' + $store.category.color }"
                >
                  Read More
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </section>

    <section id="pages" v-if="$store.project.pages.length > 0">
      <div class="container">
        <div class="sidebar">
          <div class="flex w-full mb-2 justify-between">
            <h3>Pages</h3>
            <div class="inline-flex">
              <div
                class="nav_button back"
                :style="style"
                @click="changeSlide('prev', 'news')"
              >
                <svg
                  class="chevron"
                  xmlns="http://www.w3.org/2000/svg"
                  stroke="white"
                  stroke-width="8"
                  viewBox="0 0 54 90"
                >
                  <path
                    d="M2.6 51.4l36 36a9 9 0 0012.8 0 9 9 0 000-12.8L21.7 45l29.7-29.6A9 9 0 1038.6 2.6l-36 36a9 9 0 000 12.8z"
                    :fill="'#' + $store.category.color"
                    fill-rule="nonzero"
                  />
                </svg>
              </div>

              <div
                class="nav_button next"
                :style="style"
                @click="changeSlide('next', 'news')"
              >
                <svg
                  class="chevron"
                  xmlns="http://www.w3.org/2000/svg"
                  stroke="white"
                  stroke-width="8"
                  viewBox="0 0 54 90"
                >
                  <path
                    d="M51.4 51.4l-36 36a9 9 0 01-12.8 0 9 9 0 010-12.8L32.3 45 2.6 15.4A9 9 0 1115.4 2.6l36 36a9 9 0 010 12.8z"
                    :fill="'#' + $store.category.color"
                    fill-rule="nonzero"
                  />
                </svg>
              </div>
            </div>
          </div>
          <p class="updated">
            Last updated {{ $store.project.pages[0].created_at | dateShort }} at
            {{ $store.project.pages[0].created_at | timeShort }}
          </p>
        </div>

        <div class="content">
          <swiper class="swiper" :options="pageSlider" ref="news">
            <swiper-slide
              class="page_item"
              v-for="(item, index) in $store.project.pages"
              :key="index"
            >
              <a
                :href="item.url"
                class="
                  title
                  inline-block
                  leading-tight
                  tracking-tight
                  w-full
                  text-xl
                  pb-2
                  mt-1
                  font-light
                "
                :style="{ color: '#' + $store.category.color }"
                v-html="markdownRender(item.title)"
              ></a>
              <div
                class="excerpt"
                v-if="
                  item.excerpt &&
                    getPreview(item.excerpt) &&
                    getPreview(item.excerpt).length > 16
                "
              >
                {{ getPreview(item.excerpt) }}
              </div>
              <div
                class="open_button"
                @click="getPage(item.id)"
                :style="{ backgroundColor: '#' + $store.category.color }"
              >
                Open Page
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </section>

    <section id="events" v-if="$store.project.events">
      <div class="container">
        <div class="sidebar">
          <div class="flex w-full mb-4 pb-4 border-b justify-between">
            <h3>Events</h3>
            <div class="inline-flex">
              <div
                class="nav_button back"
                :style="style"
                @click="$refs.vuecal.previous()"
              >
                <svg
                  class="chevron"
                  xmlns="http://www.w3.org/2000/svg"
                  stroke="white"
                  stroke-width="8"
                  viewBox="0 0 54 90"
                >
                  <path
                    d="M2.6 51.4l36 36a9 9 0 0012.8 0 9 9 0 000-12.8L21.7 45l29.7-29.6A9 9 0 1038.6 2.6l-36 36a9 9 0 000 12.8z"
                    :fill="'#' + $store.category.color"
                    fill-rule="nonzero"
                  />
                </svg>
              </div>

              <div
                class="nav_button next"
                :style="style"
                @click="$refs.vuecal.next()"
              >
                <svg
                  class="chevron"
                  xmlns="http://www.w3.org/2000/svg"
                  stroke="white"
                  stroke-width="8"
                  viewBox="0 0 54 90"
                >
                  <path
                    d="M51.4 51.4l-36 36a9 9 0 01-12.8 0 9 9 0 010-12.8L32.3 45 2.6 15.4A9 9 0 1115.4 2.6l36 36a9 9 0 010 12.8z"
                    :fill="'#' + $store.category.color"
                    fill-rule="nonzero"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div class="info_date">
            <span
              class="underline mb-2"
              :style="'color: #' + $store.category.color"
              @click="$refs.vuecal.switchView('month')"
            >
              {{ calendar.month }}
            </span>
          </div>
          <p>No upcoming events</p>
        </div>
        <div class="content">
          <VueCal
            :on-event-click="onEventClick"
            ref="vuecal"
            :style="$store.style"
            :dblclickToNavigate="false"
            :clickToNavigate="true"
            :events="getEvents($store.project.events)"
            small
            :timeFrom="540"
            :timeTo="1240"
            @view-change="updateMonth"
            :selectedDate="calendar.current"
            :hide-weekdays="[7]"
            :hideTitleBar="true"
            :hideViewSelector="true"
            :active-view="calendar.view"
            :disable-views="['years', 'year', 'day']"
            style="height: 380px"
          />
        </div>
      </div>
    </section>

    <section id="contributors">
      <div class="container">
        <div class="sidebar">
          <div class="flex w-full mb-4 pb-4 border-b justify-between">
            <h3>Contributors</h3>
          </div>
        </div>
        <div class="content">
          <a
            class="contributor_item"
            :style="'background: url(' + getProfile(item, 'avatar') + ')'"
            v-for="(item, index) in $store.project.users"
            :key="index"
            :content="
              `<div class='tooltip_custom' :style='{background: '#' + $store.category.color}'><h2>${getProfile(
                item,
                'name'
              )}</h2></div>`
            "
            v-tippy="{
              followCursor: true,
              offset: '0, 20',
              touch: false,
              theme: 'light',
              placement: 'bottom',
              arrow: false,
              arrowType: 'round',
              animation: 'shift-away',
            }"
            :href="getProfile(item, 'url')"
            target="_blank"
          >
          </a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import { directive } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";
import moment from "moment";
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
export default {
  name: "Project",
  components: {
    Header,
    VueCal,
  },
  directives: {
    swiper: directive,
  },
  data: function() {
    return {
      showModal: false,
    };
  },
  computed: {
    // a computed getter
    updates: function() {
      // `this` points to the vm instance
      return this.$store.project.events
        .concat(this.$store.project.news)
        .sort(function(a, b) {
          return b.created_at.localeCompare(a.created_at);
        });
    },
  },
  methods: {
    changeSlide(dir, section) {
      if (dir == "next") {
        this.$refs[section].$swiper.slideNext();
      }
      if (dir == "prev") {
        this.$refs[section].$swiper.slidePrev();
      }
    },
    updateMonth({ view, startDate, endDate, week }) {
      this.calendar.month = moment(startDate).format("MMMM YYYY");
    },
    getPreview(text) {
      var doc = document.createElement("div");
      doc.innerHTML = text;

      var children = doc.childNodes;

      for (var i = 0; i < children.length; i++) {
        console.log(children[i].nodeName);
        var name = children[i].nodeName;
        if (name == "#text") {
          console.log(children[i].textContent);
          return children[i].textContent.substring(0, 240) + "..";
        }
      }
    },
    onEventClick(event, e) {
      this.goTo("event", event.id);
      e.stopPropagation();
    },
  },
  data() {
    return {
      style: null,
      subSlider: {
        speed: 600,
        autoHeight: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        breakpoints: {
          1024: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 0,
          },
        },
      },
      newsSlider: {
        speed: 600,
        autoHeight: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        breakpoints: {
          1024: {
            slidesPerView: "auto",
            spaceBetween: 40,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
        },
      },
      pageSlider: {
        speed: 600,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        breakpoints: {
          1024: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            autoHeight: true,
            spaceBetween: 10,
          },
        },
      },
      calendar: {
        current: moment(new Date()).format("MM-DD-YYYY"),
        view: "month",
        month: moment(new Date()).format("MMMM YYYY"),
        events: [],
      },
    };
  },
  created() {
    this.$store.type = "project";
  },
  mounted() {
    this.$store.subCategories = [];
    if (
      !this.$store.category ||
      this.$store.category.slug !== this.$route.params.id
    ) {
      this.getProject(this.$route.params.id);
      this.getSubCategories(this.$route.params.id);
    }
    var self = this;
    setTimeout(() => {
      self.$store.blob.start = false;
    }, 500);
  },
};
</script>

<style lang="scss">
#project {
  section {
    @apply w-full py-10 border-t;
    &:nth-child(odd) {
      background: #fafafa;
    }
    .container {
      @apply flex mx-auto flex-col;
      width: 75%;
      .sidebar {
        width: 30%;
        min-width: 230px;
        @apply mr-10;
        flex: 0;
      }
      .content {
        width: 100%;
        position: relative;
      }
    }
    h3 {
      @apply text-3xl font-bold;
    }
  }
}

.subslider {
  width: 100%;
}

.sub_item {
  width: 300px;
  display: inline-flex;
  @apply flex-col relative p-0 relative;
  z-index: 999;
  &:hover {
    cursor: pointer;
  }

  padding: 0 !important;
  .info {
    @apply px-6 py-4;
  }
  .item_header {
    @apply px-6;
    h3 {
      @apply inline-block;
    }
    img {
      width: 40px;
      @apply mt-4;
      height: auto;
      flex-shrink: 0;
    }
  }
  .item_bg_header {
    @apply flex flex-col justify-end relative;
    background-size: cover !important;
    background-position: 50% 50% !important;
    height: 200px;
    &:after {
      content: "";
      position: absolute;
      z-index: 1;
      width: 100%;
      display: inline-block;
      height: 150px;
      bottom: 0px;
      left: 0;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0) 100%
      );
    }
    h3 {
      position: relative;
      @apply mb-4 ml-6 text-lg;
      z-index: 99999;
    }
  }
}

.news_item {
  max-width: 50%;
  a.title {
    @apply text-lg leading-tight tracking-tight;
  }
  p.date {
    background: var(--color);
    color: white;
    @apply font-bold inline-block mb-2;
    padding: 2px 6px;
  }
  .excerpt {
    @apply text-base pr-4;
  }
}
.news_item:hover {
  cursor: pointer;
  a {
    @apply underline;
  }
}

.nav_button {
  width: 34px;
  height: 34px;
  @apply relative flex items-center justify-center;
  .chevron {
    width: 10px !important;
  }
  &:hover {
    cursor: pointer;
  }

  &.back {
    @apply mr-0;
    .chevron {
      margin-left: -3px;
    }
  }
  &.next {
    .chevron {
      margin-right: -1px;
    }
  }
}

.sidebar {
  p.updated {
    color: rgba(0, 0, 0, 0.5);
    @apply mb-4;
    padding-left: 25px;
    background-size: 15px !important;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 125'%3E%3Cpath fill='rgba(0,0,0,0.5)' d='M46 85a35 35 0 1127-62L59 35l38 9-2-40-13 11C75 9 67 4 57 3a47 47 0 1032 75l-10-8c-7 10-20 16-33 15z'/%3E%3C/svg%3E")
      no-repeat 0 4px;
  }
}

#news {
  padding-top: 3.4rem !important;
  .swiper {
    overflow: hidden;
    width: 100%;
  }
  .featured {
    width: 100%;
    height: 300px;
    div {
      width: 500px;
      height: 300px;
      background-size: cover !important;
    }
  }
  .item_image {
    width: 200px;
    flex-shrink: 0;
    background-size: contain !important;
    background-position: 50% 50% !important;
    height: 300px;
  }
}

.open_button {
  @apply text-xs text-white rounded-full py-2 font-bold mt-4 inline-block mb-2 px-4;
  &:hover {
    cursor: pointer;
  }
}

#pages {
  .page_item {
    min-height: 320px;
    float: left;
    @apply border bg-white flex flex-col pb-3 pt-6 px-5;
    align-items: start;
    border-top: 2px solid var(--color);
    .excerpt {
      font-size: 15px;
      @apply leading-tight;
      flex: 1;
    }
    a {
      @apply inline-flex w-auto font-bold;
      img {
        height: 25px;
        width: auto;
        @apply mr-3 float-left inline;
      }
    }
  }
}

section#events {
  .container {
    @apply flex-row;
    .content {
      width: auto;
    }
  }
  .info_date {
    &:hover {
      @apply cursor-pointer;
    }
  }
  .vuecal {
    box-shadow: none;
    .vuecal__cell-events {
      padding: 10px;
    }
    .vuecal__time-cell-label {
      display: none;
    }
    .vuecal__time-column {
      width: 0;
    }
    .vuecal__weekdays-headings {
      padding-left: 0;
    }
    .vuecal__cell--out-of-scope {
      display: none;
    }
    .vuecal__event {
      padding: 4px;
      width: 97%;
      height: auto !important;
      color: white;
      @apply border text-left;
      background-color: var(--color);
      border-radius: 4px;
      font-size: 10px;
      opacity: 1;
      transition: all 0.3s ease;
      &:hover {
        opacity: 1;
        cursor: pointer;
        transform: scale(1.01);
      }
      .vuecal__event-title {
        font-weight: bold;
        text-transform: capitalize;
      }
    }
  }
  .vuecal__title-bar {
    background: none;
  }
  .today {
    .weekday-label {
      color: var(--color);
      font-weight: 900;
    }
  }
}

#contributors {
  .content {
    &:hover {
      .contributor_item {
        filter: grayscale(100%);

        &:hover {
          filter: grayscale(0%);

          z-index: 999;
          position: relative;
          box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
          transition: filter 0s ease, transform 0.3s ease;
        }
      }
    }
    .contributor_item {
      height: 70px;
      float: left;
      transition: filter 0.3s ease, transform 0.3s ease;

      @apply flex items-center border;
      width: 69.2px;
      flex-shrink: 0;
      background-size: cover !important;
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.weekday-label {
  span {
    @apply font-light;
    color: var(--color);
    font-size: 1.15rem;
  }
}
.vuecal--month-view {
  width: 80%;
}
.vuecal--week-view {
  width: 100%;
  transition: width 0.3s ease;
}
.month-view {
  .vuecal__cell--has-events {
    border-bottom: 2px solid var(--color);
    color: var(--color);
    .vuecal__cell-date {
      margin-top: -20px;
      @apply font-bold;
    }
    .vuecal__cell-events-count {
      background: var(--color);
      color: white;
      padding: 10px;
      @apply font-bold flex items-center;
      margin-top: -5px;
    }
  }
}

.light-theme {
  color: #fff;
  box-shadow: 0 0 20px 4px rgba(154, 161, 177, 0.15),
    0 4px 80px -8px rgba(36, 40, 47, 0.25),
    0 4px 4px -2px rgba(91, 94, 105, 0.15);
  background-color: var(--color) !important;
}
.light-theme[x-placement^="top"] .tippy-arrow {
  border-top: 8px solid var(--color);
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
}
.light-theme[x-placement^="bottom"] .tippy-arrow {
  border-bottom: 8px solid var(--color);
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
}
.tippy-tooltip.light-theme[x-placement^="left"] .tippy-arrow {
  border-left: 8px solid var(--color);
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
}
.light-theme[x-placement^="right"] .tippy-arrow {
  border-right: 8px solid var(--color);
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
}
.light-theme .tippy-backdrop {
  background-color: var(--color);
}
.light-theme .tippy-roundarrow {
  fill: var(--color);
}
.light-theme[data-animatefill] {
  background-color: initial;
}

.tippy-tooltip {
  background: var(--color) !important;
  color: white;
  border: 0 !important;
  border-radius: 100px !important;
  margin: 0;
  z-index: 99999999;
  position: relative;
}

.tooltip_custom {
  background: var(--color) !important;
  padding: 4px;
  border: 0 !important;

  border-radius: 100px !important;
  text-align: left;
  color: white !important;
  h2 {
    @apply font-bold text-base;
  }
  p {
    font-size: 13px;
  }
}

@media only screen and (max-width: 600px) {
  .news_item {
    max-width: 100%;
    width: 100%;
  }
  section#events {
    .container {
      @apply flex-col;
      .vuecal--month-view {
        width: 100%;
      }
    }
  }
}
</style>
